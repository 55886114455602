import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { ref } from 'vue'

const id = ref(sessionStorage.getItem('devices_id') || '')
FingerprintJS.load().then(async (fp) => {
  // Get the visitor identifier when you need it.
  const result = await fp.get()
  id.value = result.visitorId
  sessionStorage.setItem('devices_id', id.value)
})

export default id
