import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

export const useUserStore = defineStore('user', () => {
  const userInfo = reactive({})
  const isLogin = ref(false)

  async function autoLogin() {
    if (Cookies.get('token')) {
      const { data: userInfoData } = await mineFetch('/v1/user/info').json().get()
      if (userInfoData.value.code === 0) {
        Object.assign(userInfo, {
          ...userInfoData.value.data,
          level: Number(userInfoData.value.data.userRankName.replace('VIP ', '')),
          avatar: userInfoData.value.data?.avatar.startsWith('http') ? userInfoData.value.data.avatar : `${import.meta.env.VITE_APP_API}/${userInfoData.value.data.avatar}`,
        })
        isLogin.value = true
        getClubInfo()
      }

      else { Cookies.remove('token') }
    }
  }
  // 获取用户俱乐部会员信息
  const clubInfo = reactive({})
  async function getClubInfo() {
    if (Cookies.get('token')) {
      const { data: club } = await paymentFetch('/v2/club/user').json().get()
      if (club.value.code === 0)
        Object.assign(clubInfo, club.value.data)
    }
  }
  return {
    userInfo,
    isLogin,
    autoLogin,
    clubInfo,
    getClubInfo,
  }
})
