import type { UseFetchOptions } from '@vueuse/core'
import { createFetch } from '@vueuse/core'
import Cookies from 'js-cookie'
import { Toast } from 'vant'
import type { Router } from 'vue-router'
import { useAppStore } from '~/store/app'
let _router
function noToast(router: Router) {
  return ['display'].includes(router.currentRoute.value?.name || router.currentRoute.value?.path)
}
export const fetchOptions = (router: Router): UseFetchOptions => ({
  async beforeFetch(ctx) {
    _router = router
    const appStore = useAppStore()
    const { options } = ctx

    if (Cookies.get('token')) {
      options.headers = {
        ...options.headers,
        'Mine-User-Token': Cookies.get('token') || '',
      }
    }

    if (options.method?.toLowerCase() === 'get') {
      const searchParams = new URLSearchParams({
        vlang: appStore.lang,
      })
      if (!ctx.url.includes('?'))
        ctx.url += `${ctx.url.endsWith('?') ? '' : '?'}${searchParams}`
      else if (!ctx.url.includes('vlang_config'))
        ctx.url += `&${searchParams}`
    }
    else if (options.body) {
      options.body = JSON.stringify({
        ...JSON.parse(options.body as string),
        vlang: appStore.lang,
      })
    }

    return ctx
  },
  async afterFetch(ctx) {
    try {
      const json = typeof ctx.data === 'string' ? JSON.parse(ctx.data) : ctx.data
      if (json.code !== 0 && !noToast(router) && !ctx.response.url.includes('not_notice=true')) {
        Toast(json.msg)
        if (ctx.data.code === 2023) {
          setTimeout(() => {
            _router.push({ name: 'personal-authentication' })
          }, 2000)
        }
      }

      if (json.code === 1000 && !['register-yn', 'register-yd', 'login', 'forget', 'region-select', 'slashOrders', 'slashDetail', 'display', 'language', 'pc-about-us'].includes(router.currentRoute.value.name))
        router.push({ name: 'login' })
    }
    catch (e) {

    }
    return ctx
  },
  onFetchError(ctx) {
    console.error(ctx)

    if (!noToast(router))
      Toast('networkError')

    return ctx
  },
})

export const usePaymentFetch = (router: Router) => createFetch({
  baseUrl: import.meta.env.VITE_PAYMENT_API,
  options: fetchOptions(router),
  fetchOptions: {
    mode: 'cors',
  },
})

// export const useMineFetch = (router: Router) => createFetch({
//   baseUrl: import.meta.env.VITE_APP_API,
//   options: fetchOptions(router),
//   fetchOptions: {
//     mode: 'cors',
//   },
// })

export const useMineFetch = (router: Router, base) => createFetch({
  baseUrl: base || import.meta.env.VITE_APP_API,
  options: fetchOptions(router),
  fetchOptions: {
    mode: 'cors',
  },
})
