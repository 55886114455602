


export const layouts = {
'InnerPage': () => import('/www/wwwroot/vueh5/src/layouts/InnerPage.vue'),
'auth': () => import('/www/wwwroot/vueh5/src/layouts/auth.vue'),
'home': () => import('/www/wwwroot/vueh5/src/layouts/home.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
