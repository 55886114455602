<script setup lang="ts">
import { userInfo } from 'os'
import { useI18n } from 'vue-i18n'
import { useAppStore } from './store/app'
import { useUserStore } from './store/user'

const LISTEN_INTERVAL = 1000 * 20
const { t } = useI18n()

const luckyRentTm = ref()
const luckRentPopInfo = ref<any>({
  wins: [],
  lose: [],
})

const { onFetchResponse, data: titleData } = mineFetch<any>('/v1/web_title').json()

const { execute } = mineFetch<string>('/v2/order/getluckyinfo', {
  initialData: [],
  immediate: false,
  afterFetch(ctx) {
    setListenTmer()
    if (ctx.data.code == 0) {
      const wins = ctx.data?.data?.wins || []
      const lose = ctx.data?.data?.lose || []
      setLucyRentPopInfo(wins, lose)
    }
    return ctx
  },
}).json()

onFetchResponse(() => {
  if (titleData?.value?.code === 0)
    useTitle(titleData.value.data.info.web_title)
})

const appStore = useAppStore()
const userStore = useUserStore()

const luckLose = computed(() => {
  const show2 = luckRentPopInfo.value.lose ?? []
  return show2
})
const luckWin = computed(() => {
  const show1 = luckRentPopInfo.value.wins ?? []
  return show1
})
const luckRentPop = computed(() => {
  const show1 = luckRentPopInfo.value.wins ?? []
  const show2 = luckRentPopInfo.value.lose ?? []
  return (show1.length || show2.length) > 0
})

const setLucyRentPopInfo = (win: any, lose: any) => {
  luckRentPopInfo.value.lose = lose
  luckRentPopInfo.value.wins = win
}

const closeWin = () => {
  setLucyRentPopInfo([], [])
}

const setListenTmer = () => {
  if (luckyRentTm.value)
    clearTimeout(luckyRentTm.value)

  luckyRentTm.value = setTimeout(() => {
    execute()
  }, LISTEN_INTERVAL)
}

onMounted(() => {
  const route = useRoute()
  watch(() => route.path, () => {
    if (!['register', 'login', 'forget', 'region-select'].includes(route.name as string))
      userStore.autoLogin()
  }, { immediate: true })
})

watch(() => userStore.isLogin, (val) => {
  if (val && import.meta.env.VITE_APP_TARGET === 'green') {
    execute()
    setListenTmer()
  }
}, { immediate: true })

onBeforeUnmount(() => {
  if (luckyRentTm.value)
    clearTimeout(luckyRentTm.value)
})
</script>

<template>
  <main
    bg-second
    w-full
    h-full
  >
    <router-view :key="appStore.lang" />
    <VanPopup
      v-model:show="luckRentPop"
      position="center"
      rounded-10px
    >
      <div
        bg-second
        w-315px
        pb-15px
        flex
        rounded-7px
        overflow="hidden"
        flex-col
        items-center
      >
        <div
          w-315px
          h52px
          relative
          flex
          items-center
          justify="center"
        >
          <img
            absolute
            top-0
            left-0
            :src="`/images/banner-${appTarget}.png`"
            w-315px
            h-52px
            mb-20px
            alt=""
          >
          <span
            text-strong
            relative
            z-10
            case-capital
          >{{ t('liveTip') }}</span>
        </div>
        <div
          w-286px
          min-h-135px
          max-h-200px
          overflow-y-auto
          m="x15px t-24px b19px"
          p="x-15px y10px"
          rounded-8px
        >
          <div
            v-for="(item) in luckWin"
            :key="item"
            text-13px
            color="#999"
          >
            {{ t('winNotice', { num: item }) }}
          </div>
          <div
            v-for="(item) in luckLose"
            :key="item"
            text-13px
            color="#999"
          >
            {{ t('loseNotice', { num: item }) }}
          </div>
        </div>
        <div flex>
          <div
            w-100px
            h-40px
            rounded-20px
            case-capital
            class="theme-btn"
            flex="~"
            text-strong
            items-center
            justify="center"
            @click="closeWin"
          >
            {{ t('ok') }}
          </div>
        </div>
      </div>
    </VanPopup>
  </main>
</template>

<style>
.customHtml * {
  background: none !important;
}
.customHtml video{
  display: initial;
}
</style>
