import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import { Toast } from 'vant'
import Cookies from 'js-cookie'
import VueEcharts from 'vue-echarts'
import * as echarts from 'echarts'
import tim from './tim'
import App from './App.vue'
import './utils/devices'
import '@unocss/reset/tailwind.css'
import './styles/main.less'
import 'uno.css'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import { setupDirectives } from './utils/directives'
import { usePaymentFetch } from './composables/useFetch'
import { GridComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
const appTarget = import.meta.env.VITE_APP_TARGET
const appCountry = import.meta.env.VITE_APP_COUNTRY
window.appTarget = appTarget
// window.appCountry = appCountry

export const app = createApp(App)
echarts.use([CanvasRenderer as any, GridComponent as any])
app.component('VChart', VueEcharts)

console.log(setupLayouts(generatedRoutes))
export const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: setupLayouts(generatedRoutes),
})

const pinia = createPinia()
app.use(pinia)
app.config.globalProperties.tim = tim

function setUpSiteInfo() {
  document.body.classList.add(`merchant-${appTarget}`)
  const link = document.createElement('link')
  if (appTarget === 'yd' && appCountry === '1')
    link.href = `/images/logo-${appTarget}-711.png`

  else
    link.href = `/images/logo-${appTarget}.png`

  link.type = 'images/png'
  link.rel = 'icon'
  document.head.appendChild(link)
}

setUpSiteInfo()
const setBodyClass = (to) => {
  if (to.name === 'display' && document.documentElement.clientWidth > 520)
    document.body.classList.add('pc')
  else
    document.body.classList.remove('pc')
}
router.beforeEach((to, from, next) => {
  setBodyClass(to)
  if (to.query.fuid)
    sessionStorage.setItem('fuid', to.query.fuid as string)
  if (to.query.fcode)
    sessionStorage.setItem('fcode', to.query.fcode as string)

  if (to.name === 'slashDetail' && !sessionStorage.getItem('slashDetail'))
    return next({ name: 'slashOrders', query: to.query })

  const routes = router.getRoutes()
  if (appTarget && !to.path.endsWith(`-${appTarget}`)) {
    const target = routes.find(item => item.path === `${to.path}-${appTarget}`)
    if (target)
      return next({ path: target.path, query: to.query })
  }
  if (to.path === '/')
    return next({ path: '/home', query: to.query })
  if (to.name && !['register-yn', 'register-yd', 'login', 'forget', 'region-select', 'slashOrders', 'slashDetail', 'display', 'language', 'pc-about-us', 'recaptcha'].includes(to.name as string) && !Cookies.get('token'))
    next({ name: 'login', replace: true })

  else if (to.name === 'login' && Cookies.get('token'))
    next({ name: 'home', replace: true })
  else next()
})

const messages = Object.fromEntries(
  Object.entries(
    import.meta.glob<{ default: any }>('../locales/*.json', { eager: true }))
    .map(([key, value]) => {
      const json = key.endsWith('.json')
      return [key.slice(11, json ? -5 : -4), value.default]
    }),
)

Toast.allowMultiple(true)

Toast.setDefaultOptions({
  duration: 2000,
})

Toast.setDefaultOptions('loading', {
  iconSize: 40,
  duration: 0,
})
export const i18n = createI18n({
  legacy: false,
  locale: Cookies.get('lang') || (appCountry === '5' ? 'cn' : 'en'), // 孟加拉演示站默认中文
  messages,
})
app.use(i18n)
app.config.globalProperties.appTarget = appTarget

window.mineFetch = useMineFetch(router)
window.paymentFetch = usePaymentFetch(router)
// window.mineFetch2 = useMineFetch(router, 'http://192.168.2.198:81'
// window.lineFetch = getLineData(router)
app.use(router)
app.mount('#app')

setupDirectives(app)
