import Cookies from 'js-cookie'
import { defineStore } from 'pinia'
import { i18n } from '../main'
const appCountry = import.meta.env.VITE_APP_COUNTRY
export const useAppStore = defineStore('app', () => {
  const lang = ref(Cookies.get('lang') || (appCountry === '5' ? 'cn' : 'en'))// 孟加拉演示站默认中文)

  const isAnnouncePopped = $ref(false)
  // 幸运租赁中奖
  const luckRentPopInfo = ref<any>({
    wins: [],
    lose: [],
  })
  const setLucyRentPopInfo = (wins: string[], lose: string[]) => {
    luckRentPopInfo.value.wins = wins
    luckRentPopInfo.value.lose = lose
  }
  const { data: announceData, execute: getAnnouncement } = mineFetch(
    '/v1/announcement_pop_up',
    {
      initialData: '',
      immediate: false,
      afterFetch(ctx) {
        if (ctx.data.code === 0)
          ctx.data = ctx.data.data.info.announcement_pop_up

        return ctx
      },
    },
  ).json()

  const { data: onlineBankConfig } = mineFetch(
    '/v2/online_bank/profile',
    {
      initialData: {},
      afterFetch(ctx) {
        if (ctx.data.code === 0)
          ctx.data = ctx.data.data

        return ctx
      },
    },
  ).json()

  const { data: configData } = mineFetch(
    `/v1/vlang_config?${new URLSearchParams({
      url: location.host,
      // url: 'ipgin.net',
    })}`,
    {
      afterFetch(ctx) {
        if (ctx.data.code === 0) {
          ctx.data = ctx.data.data[0]
          if (!Cookies.get('lang')) {
            const vlang = ctx.data?.vlang || (appCountry === '5' ? 'cn' : 'en') // 孟加拉演示站默认中文
            if (lang.value !== vlang)
              lang.value = vlang
            i18n.global.locale.value = lang.value
            Cookies.set('lang', lang.value)
          }
        }

        getAnnouncement()

        return ctx
      },
    },
  ).json()

  const bannerUrl = ['711', 'green'].includes(appTarget) ? '/v1/index/banner' : '/v1/index/get_banner_list'
  const { data: banner } = mineFetch(bannerUrl, {
    initialData: [],
    afterFetch(ctx) {
      if (ctx.data.code === 0)
        ctx.data = ctx.data.data

      return ctx
    },
  }).json()

  return {
    lang,
    isAnnouncePopped,
    banner,
    configData,
    announceData,
    onlineBankConfig,
    luckRentPopInfo,
    setLucyRentPopInfo,
  }
})
