import type { App, DirectiveBinding } from 'vue'
import { priceUnitTransform } from './index'
import { useAppStore } from '~/store/app'

export function setupDirectives(app: App) {
  function moneyInit(currency: string, { el, binding }: {
    el: HTMLElement
    binding: DirectiveBinding<any>
  }) {
    const tempNode = document.createElement('div')
    const iNode = document.createElement('i')
    iNode.innerText = currency
    iNode.style.fontStyle = 'normal'
    if (binding.arg)
      iNode.style.fontSize = `${binding.arg}em`

    if (typeof binding.value === 'undefined')
      return ''

    if (binding.value === null) {
      tempNode.appendChild(iNode)
      return el.innerHTML = tempNode.innerHTML
    }

    if (isNaN(Number(binding.value)))
      return el.innerHTML = binding.value

    let limit
    if (typeof binding.arg === 'number')
      limit = binding.arg

    // 印尼711 不转换直接显示
    if (appTarget === 'yd' && import.meta.env.VITE_APP_COUNTRY === '1')
      limit = Number.MAX_SAFE_INTEGER
    // 获取单元符号
    const { money, unit } = priceUnitTransform(binding.value, limit)
    const [integer, decimal] = money.split('.')

    // 创建
    const wrapNode = document.createElement('span')
    const childNode = document.createElement('em')
    const unitNode = document.createElement('span')
    const textNode = document.createTextNode(`${integer}.`)
    // 赋值
    childNode.innerText = decimal ?? ''
    iNode.style.fontStyle = 'normal'
    iNode.className = 'wrap-money-currency'
    wrapNode.className = 'wrap-money-integer'
    childNode.className = 'wrap-money-decimal'
    if (binding.arg === 'sub')
      childNode.style.fontSize = '0.7em'
    childNode.style.fontStyle = 'normal'
    el.classList.add('wrap-money')
    el.style.whiteSpace = 'nowrap'
    if (appTarget === 'yd' && import.meta.env.VITE_APP_COUNTRY === '1') {
      const textNod = document.createTextNode(`${integer}`)
      tempNode.appendChild(iNode)
      wrapNode.appendChild(textNod)

      tempNode.appendChild(wrapNode)
      el.innerHTML = tempNode.innerHTML
    }
    // 组装
    else if (appTarget === 'green') {
      tempNode.appendChild(iNode)
      wrapNode.appendChild(textNode)
      wrapNode.appendChild(childNode)
      unitNode.innerText = unit
      wrapNode.appendChild(unitNode)

      tempNode.appendChild(wrapNode)
      el.innerHTML = tempNode.innerHTML
    }
    else {
      wrapNode.appendChild(textNode)
      wrapNode.appendChild(childNode)
      unitNode.innerText = unit
      wrapNode.appendChild(unitNode)

      tempNode.appendChild(wrapNode)
      tempNode.appendChild(iNode)
      el.innerHTML = tempNode.innerHTML
    }
  }

  // 货币符号
  app.directive('money', (el, binding) => {
    const appStore = useAppStore()
    watchEffect(
      async () => {
        // moneyInit(agent.currencySymbol || user.currencySymbol, { el, binding });
        const symbol = appStore.configData?.currency

        moneyInit(symbol || '', {
          el,
          binding,
        })
      },
    )
  })
}
