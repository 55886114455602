export const priceFormat = (price = 0) =>
  price.toString().includes('.')
    ? `${price
      .toString()
      .split('.')[0]
      .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') || 0
    }.${price.toString().split('.')[1]}`
    : `${price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') || 0}.00`

export function priceUnitTransform(value: string | number, limit = 100000000) {
  let money = Number(value)
  let unit = ''

  if (money < limit) {
    return {
      money: priceFormat(money),
      unit,
    }
  }

  const config = {
    3: {
      unit: '',
      divide: 1,
    },
    6: {
      unit: 'K',
      divide: 10 ** 3,
    },
    9: {
      unit: 'M',
      divide: 10 ** 6,
    },
    12: {
      unit: 'B',
      divide: 10 ** 9,
    },
    15: {
      unit: 'T',
      divide: 10 ** 12,
    },
  }

  Object.keys(config).forEach((key: any) => {
    const item = (config as any)[key] as any
    if (money.toFixed().length <= Number(key) && !unit) {
      money = Number((money / item.divide).toFixed(2))
      unit = item.unit
    }
  })

  return {
    money: Number(money).toFixed(2),
    unit,
  }
}

export function computeStr(tms: number) {
  let hoursStr = ''; let minutesStr = ''; let secondStr = ''
  const hours = Math.floor(tms / (1 * 60 * 60)) % 24
  const minutes = Math.floor(tms / (1 * 60)) % 60
  const seconds = Math.floor(tms / 1) % 60

  if (hours < 0)
    hoursStr = '00'
  else if (hours < 10)
    hoursStr = `0${hours}`
  else
    hoursStr = hours.toString()

  if (minutes < 0)
    minutesStr = '00'
  else if (minutes < 10)
    minutesStr = `0${minutes}`
  else
    minutesStr = minutes.toString()

  if (seconds < 0)
    secondStr = '00'
  else if (seconds < 10)
    secondStr = `0${seconds}`
  else
    secondStr = seconds.toString()

  return `${hoursStr}:${minutesStr}:${secondStr}`
}
